$(document).ready(function() {

    // get form variables and submit to ztrack on form submission
    $("input.wpcf7-submit").on("click", function() {
            
        var firstname = $('span.forename input').val();
        $('#FirstName').val(firstname);

        var surname = $('span.surname input').val();
        $('#Surname').val(surname);

        var email = $('span.email input').val();
        $('#Email').val(email);

        var phone = $('span.phone input').val();
        $('#PhoneNumber').val(phone);

        if (typeof YkTrack != "undefined") {
            var track = new YkTrack({
                firstNameField: '#FirstName',
                surnameField: '#Surname',
                emailField: '#Email',
                phoneNumberField: '#PhoneNumber',
                businessAccountId: '16f58849-a832-4c9f-8a88-2d32397a7587',
                developmentId: 'e011e5d4-b1ac-4300-8762-71624226ddba'
            });
            track.submit();
        }
    });
    
    // Sticky header bar
    if($(window).scrollTop() > 0) {
        $('body').addClass('sticky');
    }
    $(window).scroll(function() {
        if($(window).scrollTop() > 0) {
            $('body').addClass('sticky');
        } else if($(window).scrollTop() === 0) {
            $('body').removeClass('sticky');
        }
    });
    
    // Burger menu
    $('.header__burger').click(function() {
        $(this).toggleClass('open');
        if($('header nav').is(':visible')) {
            $('header nav').slideUp();
        } else {
            $('header nav').slideDown();
        }
	});
    
    // Dropdown menus
    $('.dropdown .dropdown-menu').on('click', '.dropdown-item', function(e) {
        e.preventDefault();
        var link_text = $(this).html(),
            link_val = $(this).data('value');
        $(this).parent().siblings('button').html(link_text);
        $(this).parents('.dropdown').find('input[type=hidden]').val(link_val);
    });
    
    // Callback & Mailing list forms
    $('a[data-panel]').on('click', function(e) {
        e.preventDefault();
        if($('.our_developments__' + $(this).data('panel')).is(':visible')) {
            $('.our_developments__' + $(this).data('panel')).slideUp(400);
        } else {
            $('.our_developments__request-callback:visible, .our_developments__join-mailing-list:visible').hide();
            $('.our_developments__' + $(this).data('panel')).slideDown(500);
        }
    });
    
    $('.development__content').on('click', 'tr[data-property]', function() {
        $('.development__content--property_details[data-property]').hide();
        $('.development__content--property_details[data-property=' + $(this).data('property') + ']').fadeIn();
    });
    
    var map_style = [ { "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" } ] }, { "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, { "elementType": "labels.text.fill", "stylers": [ { "color": "#616161" } ] }, { "elementType": "labels.text.stroke", "stylers": [ { "color": "#f5f5f5" } ] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [ { "color": "#bdbdbd" } ] }, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#eeeeee" } ] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [ { "color": "#757575" } ] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [ { "color": "#e5e5e5" } ] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [ { "color": "#9e9e9e" } ] }, { "featureType": "road", "elementType": "geometry", "stylers": [ { "color": "#ffffff" } ] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [ { "color": "#757575" } ] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [ { "color": "#dadada" } ] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [ { "color": "#616161" } ] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [ { "color": "#9e9e9e" } ] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [ { "color": "#e5e5e5" } ] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [ { "color": "#eeeeee" } ] }, { "featureType": "water", "elementType": "geometry", "stylers": [ { "color": "#c9c9c9" } ] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [ { "color": "#9e9e9e" } ] } ];
    
    function gmap() {
        if($('#gmap').length) {

            var lat = parseFloat($('#gmap').data('lat')),
                lng = parseFloat($('#gmap').data('lng')),
                point = {lat: lat, lng: lng},
                bounds = new google.maps.LatLngBounds();

            var map = new google.maps.Map(document.getElementById('gmap'), {
                center: point,
                zoom: 15,
                scrollwheel: false,
                styles: map_style
            });

            var marker = new google.maps.Marker({
                position: {lat: parseFloat(lat), lng: parseFloat(lng)},
                map: map,
                title: 'Mantle Developments',
                icon: {
                    url: '/images/marker.png'
                }
            });

            bounds.extend(marker.position);

            google.maps.event.addListener(map, 'bounds_changed', function() {
                google.maps.event.trigger(map, 'resize');
            });

        }
    }
    $('.development__content--map').on('click', '.button', function(e) {
        e.preventDefault();
        gmap();
    });
    
    // Expanding footer menu links on mobile
    if($(window).width() <= 992) {
        $('footer').on('click', 'h6', function() {
            $(this).toggleClass('open').siblings().slideToggle();
        });
    }
    
});